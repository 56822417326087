import React, { useState, useEffect, useCallback, useRef } from "react"
import { Icon } from "helpers/getIcon"
import useMedia from "hooks/useMedia.js"
import { setAutoPlay } from "./setAutoPlay"
import { ReactComponent as ArrowRight } from "images/icons/arrow-right.svg"

import {
  CarouselContainer,
  EmblaComponent,
  SlidesContainer,
  PreviousButton,
  NextButton,
  Slide,
  Controls,
  Dots,
  DotButton,
  Buttons,
} from "./style"

//PROPS
//gap : [0,0]
//snapBack : will snap back to the start when clicking next at end (boolean)
//speed : default is 6 (number)
//slidesToShow : [mobile, desktop]
//fade : single slide fade in (boolean)
//autoPlay : (boolean)
//interval : (in seconds)
//hideDots : displayDots (boolean)
//hideButtons : (boolean)
//loop : (boolean)
//align : (string)
//draggable: boolean
//startIndex : number
//onChange: function called when a slide changes, takes index as an argument
//passedIndex : (number) you can pass in an index to trigger a scrollTo event
//dotColor : custom dot color
//onSlideClick: pass in a function that receives the index of a slide when it's clicked
//nextTrigger: pass in any variable that changes to trigger a scrollNext() event
const Carousel = ({
  className,
  children,
  gap,
  slidesToShow,
  fade,
  snapBack,
  speed,
  autoPlay,
  interval,
  loop,
  hideDots,
  align,
  draggable,
  hideButtons,
  background,
  startIndex,
  onChange,
  passedIndex,
  dotColor,
  onSlideClick,
  dragFree,
  nextTrigger,
}) => {
  const carouselRef = useRef(null)
  const buttonsRef = useRef(null)
  const dotsRef = useRef(null)
  const carouselElement =
    carouselRef.current && carouselRef.current.container.current

  const [embla, setEmbla] = useState(null)
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false)
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [scrollSnaps, setScrollSnaps] = useState([])

  // const arrowIcon = <Icon name="arrow-left" />
  const arrowIcon = <ArrowRight />

  const isDesktop = useMedia(
    // Media queries
    ["(min-width: 1024px)"],
    //options
    [true],
    // default
    false
  )

  const scrollTo = useCallback(
    index => {
      embla.scrollTo(index)
    },
    [embla]
  )

  const scrollPrev = useCallback(() => {
    embla.scrollPrev()
  }, [embla, scrollSnaps, selectedIndex])

  const scrollNext = useCallback(() => {
    embla.scrollNext()

    //snapsBack to start
    if (snapBack && selectedIndex === scrollSnaps.length - 1) {
      embla.scrollTo(0)
    }
  }, [embla, scrollSnaps, selectedIndex])

  //Main Embla functionality
  useEffect(() => {
    //slide selection handler
    const onSelect = () => {
      setSelectedIndex(embla.selectedScrollSnap())
      setPrevBtnEnabled(embla.canScrollPrev())
      setNextBtnEnabled(embla.canScrollNext())
      //custom onChange stuff
      onChange && onChange(embla.selectedScrollSnap())
    }

    if (embla) {
      const autoPlayer = setAutoPlay(embla, interval)
      onSelect()
      setScrollSnaps(embla.scrollSnapList())
      embla.on("select", () => {
        // console.log(`Current index is ${embla.selectedScrollSnap()}`)
        onSelect()
      })

      // resized browser
      embla.on("resize", () => {
        setScrollSnaps(embla.scrollSnapList())
      })

      //initializing autoPlay
      if (autoPlay) {
        autoPlayer.play()
        carouselElement.addEventListener("mousedown", autoPlayer.play, false)

        //
        !hideButtons &&
          buttonsRef.current.addEventListener(
            "mousedown",
            autoPlayer.play,
            false
          )

        //
        !hideDots &&
          dotsRef.current.addEventListener("mousedown", autoPlayer.play, false)

        //
      }

      //
      return () => {
        autoPlayer.stop()
      }
    }
  }, [embla])

  const options = {
    loop: loop || false,
    align: align || "start",
    containScroll: true,
    speed: speed || 6,
    draggable: draggable,
    dragFree,
    startIndex: startIndex || 0,
  }

  //Re-initializes the` embla on re-size
  useEffect(() => {
    // console.log("re-initializing carousel with new props")

    if (embla) {
      // console.log("options:", options)

      embla.changeOptions({
        ...options,
      })
    }
  }, [isDesktop])

  //scrolls to a passed slide
  useEffect(() => {
    if ((passedIndex || passedIndex === 0) && embla) {
      // console.log('passedIndex:', passedIndex)
      scrollTo(passedIndex)
    }
  }, [passedIndex, embla])

  //if nextTrigger updates from null it triggers scrollNext()
  useEffect(() => {
    if ((nextTrigger || nextTrigger === 0) && embla) {
      scrollNext()
    }
  }, [nextTrigger, embla])

  const handleSlideClick = index => {
    if (embla.clickAllowed() && onSlideClick) {
      // console.log("index:", index)
      onSlideClick(index)
    }
  }

  return (
    <CarouselContainer
      gap={gap}
      fade={fade}
      className={className}
      background={background}
    >
      <EmblaComponent
        loop={loop}
        className="embla-carousel"
        emblaRef={setEmbla}
        fade={fade}
        options={options}
        ref={carouselRef}
        background={background}
      >
        <SlidesContainer
          loop={loop}
          gap={gap}
          fade={fade}
          slidesToShow={fade ? 1 : slidesToShow}
          background={background}
        >
          {children.map((Child, index) => (
            <Slide
              fade={fade}
              key={index}
              index={index}
              onClick={() => handleSlideClick(index)}
            >
              {Child}
            </Slide>
          ))}
        </SlidesContainer>
      </EmblaComponent>

      {!hideDots && !hideDots && (
        <Controls>
          {!hideDots && (
            <Dots ref={dotsRef}>
              {scrollSnaps.map((snap, index) => (
                <DotButton
                  aria-label={`Page ${index + 1}`}
                  dotColor={dotColor}
                  selected={index === selectedIndex}
                  onClick={() => scrollTo(index)}
                  key={index}
                />
              ))}
            </Dots>
          )}

          {!hideButtons && (
            <Buttons ref={buttonsRef}>
              <PreviousButton
                disabled={!prevBtnEnabled}
                onClick={scrollPrev}
                icon={arrowIcon}
                aria-label="Previous page"
              />
              <NextButton
                disabled={!nextBtnEnabled && !snapBack}
                onClick={scrollNext}
                icon={arrowIcon}
                aria-label="Next page"
              />
            </Buttons>
          )}
        </Controls>
      )}
    </CarouselContainer>
  )
}

export default Carousel

Carousel.defaultProps = {
  slidesToShow: [1.3, 3],
}
