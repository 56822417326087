import React, { useState, useLayoutEffect, useRef } from "react"
import useMedia from "hooks/useMedia.js"
import { Icon } from "helpers/getIcon"
import AvenueImg from "components/AvenueImg"
import { CSSTransition, TransitionGroup } from "react-transition-group"
import "./transitions.css"

import {
  HeroHeaderContainer,
  HeroHeaderButton,
  Banner,
  Title,
  HeaderCarousel,
  Text,
  ImageWrapper,
  BannerWrapper,
} from "./style"

const HeroHeader = ({ homeBannerItems }) => {
  const [slideContent, setSlideContent] = useState(homeBannerItems[0])

  const handleSlideChange = index => {
    setSlideContent(homeBannerItems[index])
  }

  const firstUpdate = useRef(true)
  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    }
  })

  const isMobile = useMedia(["(max-width: 767px)"], [true], false)

  const arrowIcon = <Icon name="arrow-right" />

  return (
    <HeroHeaderContainer>
      <HeaderCarousel
        loop
        draggable={isMobile}
        autoPlay
        interval={5}
        fade
        hideButtons
        onChange={handleSlideChange}
        firstRun={firstUpdate.current}
      >
        {homeBannerItems.map((slide, index) => {
          return (
            <div key={index}>
              <ImageWrapper key={index}>
                <AvenueImg
                  fluid={slide.image}
                  loading={index === 0 ? "eager" : "lazy"}
                />
              </ImageWrapper>
              <BannerWrapper carousel>
                <Banner>
                  <Title>{slide.heading}</Title>
                  <Text>{slide.description}</Text>
                  <HeroHeaderButton
                    label={slide.linkText}
                    icon={arrowIcon}
                    link={slide.link}
                  />
                </Banner>
              </BannerWrapper>
            </div>
          )
        })}
      </HeaderCarousel>
      <TransitionGroup className="transitionGroup">
        <CSSTransition
          key={slideContent.heading}
          in={true}
          appear={true}
          timeout={1000}
          mountOnEnter={true}
          unmountOnExit={true}
          enter={true}
          exit={true}
          classNames="fade"
        >
          <BannerWrapper>
            <Banner>
              <Title>{slideContent.heading}</Title>
              <Text>{slideContent.description}</Text>
              <HeroHeaderButton
                label={slideContent.linkText}
                icon={arrowIcon}
                link={slideContent.link}
              />
            </Banner>
          </BannerWrapper>
        </CSSTransition>
      </TransitionGroup>
    </HeroHeaderContainer>
  )
}

export default HeroHeader
