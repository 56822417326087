import styled from "styled-components"
import { device } from "breakpoints"
import { Button } from "../Button"
import { Icon } from "../Button/style"
import { Carousel } from "components/Carousel"
import { Controls, Dots, SlidesContainer, Slide } from "../Carousel/style"

export const HeaderCarousel = styled(Carousel)`
  position: relative;
  ${SlidesContainer} {
    height: auto;
    padding-top: 83.04% !important;

    @media ${device.tablet} {
      padding-top: 50% !important;
    }

    @media ${device.desktop} {
      padding-top: 0 !important;
      height: 674px;
      max-width: 100% !important;
    }
  }

  ${Slide} {
    /* opacity: ${props => (props.firstRun ? "1" : "0")}; */
    &.is-selected {
      transition: opacity ${props => (props.firstRun ? "0s" : "2.5s")};
    }
  }

  ${Controls} {
    display: flex;
    position: absolute;
    bottom: 35px;
    left: 50%;
    z-index: 4;
    transform: translate3d(-50%, 0%, 0);

    @media ${device.tablet} {
      position: absolute;
      top: auto;
      bottom: 60px;
      left: 50%;
    }
  }

  ${Dots} {
    display: inline-flex;
    margin: 0 auto;
    @media ${device.tablet} {
      margin: 0;
      margin-left: auto;
    }
  }
`
export const HeroHeaderContainer = styled.div`
  max-width: 1660px;
  margin: 0 auto;

  @media ${device.tablet} {
    position: relative;
  }

  .transitionGroup {
    position: relative;
    width: 100%;
  }
`

export const ImageWrapper = styled.div`
  padding-top: 84%;
  position: relative;

  @media ${device.tablet} {
    padding-top: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .gatsby-image-wrapper {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

export const Banner = styled.div`
  padding: 40px;
  padding-bottom: 38px;
  background: #10523d;
  /* position: absolute; */
  z-index: 4;

  @media ${device.tablet} {
    width: 100%;
    max-width: 375px;
    padding: 25px;
    padding-bottom: 38px;

    margin-top: auto;
    align-self: flex-end;
  }
  @media ${device.desktop} {
    max-width: 470px;
    padding: 36px 31px;
    padding-bottom: 38px;
  }
`
export const HeroHeaderButton = styled(Button)`
  border: 1px solid #ffffff;
  border-radius: 24.75px;
  font-size: 16px;
  color: #ffffff;
  letter-spacing: -0.2px;
  text-align: center;
  line-height: 23.4px;
  background: transparent;
  margin-top: 30px;
  padding: 0 0 0 30px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  width: 174px;
  height: 50px;

  ${Icon} {
    margin-left: 6px;
    margin-right: 0;
  }

  :hover {
    background: #fff;
    color: ${props => props.theme.darkGreen};
    ${Icon} {
      svg > path {
        fill: ${props => props.theme.darkGreen};
      }
    }
  }
`
export const Title = styled.h3`
  font-weight: 500;
  font-size: 26px;
  color: #ffffff;
  letter-spacing: -0.25px;
  line-height: 35px;

  @media ${device.desktop} {
    font-size: 30px;
    letter-spacing: -0.28px;
    line-height: 37px;
  }
`
export const Text = styled.p`
  font-weight: 400;
  font-size: 16px;
  color: #ffffff;
  letter-spacing: -0.2px;
  line-height: 27px;
  margin-top: 14px;

  @media ${device.desktop} {
    font-size: 18px;
    letter-spacing: -0.22px;
    line-height: 27px;
    margin-top: 17px;
  }
`

export const BannerWrapper = styled.div`
  z-index: 3;
  display: ${props => props.carousel && "none"};
  width: 100% !important;
  @media ${device.tablet} {
    display: ${props => (props.carousel ? "flex" : "none")};
    position: absolute;
    top: 0%;
    height: 100%;
    left: 50%;
    transform: translate3d(-50%, 0%, 0);
    margin: 0 auto;
    width: ${props =>
      `calc(100% - 2*${props.theme.tabletGutter}px)`} !important;
    max-width: ${props => `${props.theme.mainMaxWidth}px`};
    overflow: ${props => props.hideOverflow && "hidden"};
  }

  @media ${device.desktop} {
    width: ${props =>
      `calc(100% - 2*${props.theme.desktopGutter}px)`} !important;
  }
`
