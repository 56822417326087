import styled from "styled-components"
import { device } from "breakpoints"
import { Teaser } from "components/Teaser"
import { ImageWrapper, InfoWrapper } from "components/Teaser/style"

export const LargeHomeTeaserContainer = styled(Teaser)`
  position: relative;
  padding-top: 20px;
  margin-bottom: 30px;
  border: none;
  @media ${device.tablet} {
    flex-direction: row;
    padding: 20px 25px;
    margin-bottom: 0px;
  }

  @media ${device.desktop} {
    padding: 24px 30px;
    /* margin-bottom: 0px; */
  }

  :hover {
    svg {
      opacity: 0.8;
    }
  }

  :before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: calc(100vw - 40px);
    background: #f2efe9;
    z-index: -1;
    height: 100%;

    @media ${device.tablet} {
      width: 100%;
    }
  }

  ${InfoWrapper} {
    @media ${device.tablet} {
      padding-right: 20px;
    }

    @media ${device.desktop} {
      padding-right: 40px;
    }
    @media ${device.large} {
      padding-right: 60px;
    }
  }
  ${ImageWrapper} {
    @media ${device.tablet} {
      /* width: 66.66666%; */
      margin-bottom: 0;
      order: 2;
      padding-top: 58.3%;
      height: 100%;
      width: 68%;
      min-height: 100%;
      height: 100%;
    }

    @media ${device.desktop} {
      padding-top: 29.3%;
    }
  }
`
