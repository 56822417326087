import React from "react"
import { LargeHomeTeaserContainer } from "./style"
import { ReactComponent as Arrow } from "images/icons/arrow-right-circle.svg"

const LargeHomeTeaser = ({ data }) => {
  return (
    <LargeHomeTeaserContainer
      link={data.link}
      title={data.title}
      date={data.date || "2020-04-15T13:41:30"}
      excerpt={data.excerpt}
      img={data.featuredImage}
    >
      <Arrow style={{ marginTop: "20px" }} />
    </LargeHomeTeaserContainer>
  )
}

export default LargeHomeTeaser
